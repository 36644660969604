import React from 'react'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

interface SEOProps {
  description?: string
  title?: string
  image?: string
}

export const SEO = ({description, title, image}: SEOProps) => {
  const {site, defaultImage} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        defaultImage: file(relativePath: {eq: "metadata-image.webp"}) {
          publicURL
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaFullTitle = title ? `${title} | The Codex` : site.siteMetadata.title
  const metaImage = `http://thecodex.me${image || defaultImage.publicURL}`

  return (
    <Helmet>
      <html lang='en' />
      <title>{metaFullTitle}</title>
      <meta name='image' content={metaImage} />
      <meta name='description' content={metaDescription} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='http://thecodex.me/' />
      <meta property='og:title' content={metaFullTitle} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:image' content={metaImage} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:domain' content='thecodex.me' />
      <meta name='twitter:url' content='http://thecodex.me/' />
      <meta name='twitter:site' content={site.siteMetadata.author} />
      <meta name='twitter:creator' content={site.siteMetadata.author} />
      <meta name='twitter:title' content={metaFullTitle} />
      <meta name='twitter:description' content={metaDescription} />
      <meta name='twitter:image' content={metaImage} />
    </Helmet>
  )
}

export default SEO
