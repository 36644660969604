import React from 'react'
import styled, {css} from 'styled-components'
import {Link} from 'gatsby'
import Img, {FluidObject} from 'gatsby-image'
import {CreatorLock} from 'images/svgs'

const ProjectIcon = styled(Img)<{fluid: FluidObject}>`
  width: 100%;
  height: 100px;
  flex-shrink: 0;
  align-self: center;
`

const ProjectTagsRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
`

const tag = css`
  display: inline-block;

  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px;
  margin-left: 0px;

  font-family: var(--primary-font);
  font-weight: 600;
`

const TechTag = styled.div`
  ${tag}
  background-color: var(--violet);
`

const LevelTag = styled.div`
  ${tag}
  background-color: var(--light-grey);
  align-self: flex-start;
`

const StyledLock = styled(CreatorLock)`
  height: 25px;
  margin-right: 10px;
  margin-bottom: 0.2em;
  flex-shrink: 0;
`

const CardStyle = styled(Link)`
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  padding: 15px;
  width: 100%;
  max-width: 280px;

  background-color: var(--white);

  font-size: 0.9em;
  transition: var(--transition);

  &:hover {
    filter: var(--shadow);
  }

  h5 {
    margin-bottom: 0;
    transition: var(--transition);
  }

  &:hover h5 {
    color: var(--blue);
  }
`

const Description = styled.p`
  height: 100%;
`

interface ProjectCardObject {
  slug: string
  title: string
  description: string
  gatsbyIcon: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  free: boolean
  level: string
  categories: Array<{tag: string}>
  introduction: {
    duration: number
  }
  lessons: Array<{
    video: {
      duration: number
    }
  }>
}

const ProjectCard = ({project, ...props}: {project: ProjectCardObject}) => (
  <CardStyle to={`/projects/${project.slug}`} {...props}>
    <ProjectIcon
      fluid={project.gatsbyIcon.childImageSharp.fluid}
      alt={`${project.title} icon`}
      imgStyle={{objectFit: 'contain'}}
    />
    <h5>{project.title}</h5>
    <Description>{project.description}</Description>
    <ProjectTagsRow>
      {!project.free && <StyledLock />}
      <LevelTag>{project.level}</LevelTag>
      {project.categories && project.categories.length !== 0 && <TechTag>{project.categories[0].tag}</TechTag>}
    </ProjectTagsRow>
  </CardStyle>
)

export default ProjectCard
