import React from 'react'
import styled from 'styled-components'

import {AppHeader, AppFooter, Header, Footer} from 'components'
import {useAuth} from 'hooks'
import GlobalStyle from './globalStyle'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  min-height: calc(100vh - 75px); // 75px comes from Header height
`

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const MainLayout = ({children, theme = 'light'}) => {
  const {user} = useAuth()

  if (user) {
    return (
      <>
        <GlobalStyle />
        <AppHeader theme={theme} />
        <Content>
          <Main>{children}</Main>
          <AppFooter theme={theme === 'transparent' ? 'light' : theme} />
        </Content>
      </>
    )
  } else {
    return (
      <>
        <GlobalStyle />
        <Header theme={theme} />
        <Content>
          <Main>{children}</Main>
          <Footer />
        </Content>
      </>
    )
  }
}

export default MainLayout
