import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const TestimonialSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 50px 0;

  background-color: var(--white);
`

const TestimonialContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const SectionTitleDiv = styled.div`
  margin: 25px 0;
  text-align: center;

  h2 {
    margin: 0;
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const EyebrowText = styled.p`
  color: var(--blue);

  font-weight: bold;
`

const SubtitleText = styled.p`
  color: var(--dark-grey);
`

const Testimonial = styled.div`
  position: relative;

  font-size: 1.1em;
  font-weight: bold;

  padding: 125px 25px 25px;
  margin: 125px 0 25px;

  em {
    font-style: normal;
    color: var(--blue);
  }

  @media only screen and (min-width: 700px) {
    padding: 25px;
    margin: 25px 0;
  }
`

const BlueTestimonial = styled(Testimonial)`
  background-color: var(--light-blue);

  em {
    color: var(--blue);
  }

  @media only screen and (min-width: 700px) {
    padding-left: 125px;
    margin-left: 125px;
  }
`

const GreenTestimonial = styled(Testimonial)`
  background-color: #ecf6f3;

  em {
    color: #007048;
  }

  @media only screen and (min-width: 700px) {
    padding-right: 125px;
    margin-right: 125px;
  }
`

const YellowTestimonial = styled(Testimonial)`
  background-color: #fdf3d9;

  em {
    color: #a37500;
  }

  @media only screen and (min-width: 700px) {
    padding-left: 125px;
    margin-left: 125px;
  }
`

const ProfilePic = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 250px;

  @media only screen and (min-width: 700px) {
    top: 50%;
    left: 0;

    transform: translate(-50%, -50%);
  }
`

const ProfilePicReversed = styled(ProfilePic)`
  @media only screen and (min-width: 700px) {
    top: 50%;
    left: unset;
    right: 0;

    transform: translate(50%, -50%);
  }
`

const Name = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 50px;
`

const MoreInfo = styled.p`
  font-size: 0.8rem;
  font-weight: normal;
`

export const Testimontials = ({single = false}) => {
  const {maddie, arjun, brandon} = useStaticQuery(graphql`
    query {
      maddie: file(relativePath: {eq: "maddie.webp"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      arjun: file(relativePath: {eq: "arjun.webp"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brandon: file(relativePath: {eq: "brandon.webp"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const components = [
    <BlueTestimonial key='maddie'>
      <ProfilePic alt='maddie' fluid={maddie.childImageSharp.fluid} />
      <p>
        “For the first time in my life I’ve found a platform that can keep me engaged for more than 5 minutes. Project
        on The Codex are <em>entertaining, informative, and more valuable</em> than the countless hours of lectures I
        watched at 2x speed in school.”
      </p>
      <Name>Maddie Wilson</Name>
      <MoreInfo>University of Toronto, 2023</MoreInfo>
    </BlueTestimonial>,
    <GreenTestimonial key='arjun'>
      <ProfilePicReversed alt='arjun' fluid={arjun.childImageSharp.fluid} />
      <p>
        “CS classes at UW were overwhelming and full of theory. Through The Codex, I realized the power of Python
        programming, built <em>numerous projects for my resume</em> and ultimately landed an internship at Amazon.“
      </p>
      <Name>Arjun Malhotra</Name>
      <MoreInfo>
        University of Washington, 2022
        <br />
        SDE Internship, Amazon
      </MoreInfo>
    </GreenTestimonial>,
    <YellowTestimonial key='brandon'>
      <ProfilePic alt='brandon' fluid={brandon.childImageSharp.fluid} />
      <p>
        “I saw my friends building dope projects at hackathons. My CS classes taught how to add items to a linked list,
        but not how to <em>convert my ideas to actual applications</em>. What started as a simple Python Weather
        dashboard on The Codex transformed into the Best DS/AI project at SunHacks.
      </p>
      <Name>Brandon Wang</Name>
      <MoreInfo>
        UC Berkeley, 2024
        <br />
        Two-time hackathon winner
      </MoreInfo>
    </YellowTestimonial>,
  ]

  return (
    <TestimonialSection>
      <TestimonialContent>
        <SectionTitleDiv>
          <EyebrowText>COMMUNITY LOVE</EyebrowText>
          <h2>
            Savvy Students{' '}
            <span role='img' aria-label='Love'>
              ❤️
            </span>{' '}
            The Codex
          </h2>
          <SubtitleText>
            The Codex has helped hundreds of students find jobs and build their dream projects.
          </SubtitleText>
        </SectionTitleDiv>
        {single ? components[Math.floor(Math.random() * components.length)] : components}
      </TestimonialContent>
    </TestimonialSection>
  )
}

export default Testimontials
