import React, {useState} from 'react'
import styled from 'styled-components'
import Fuse from 'fuse.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  display: flex;
  align-items: center;

  border: solid 2px var(--medium-grey);
  border-radius: 50px;
  padding: 5px 10px;

  background-color: var(--white);
  color: var(--black);

  transition: var(--transition);

  font-family: var(--secondary-font);
  font-size: 1em;

  &:focus-within {
    border-color: var(--black);
  }
`

const InputBox = styled.input`
  border: none;
  background-color: transparent;
  color: var(--black);

  font-family: var(--secondary-font);
  font-size: 1em;

  transition: var(--transition);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--grey);
  }
`

const SearchIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: var(--medium-grey);
  transition: var(--transition);

  ${Container}:focus-within & {
    color: var(--black);
  }
`

const SearchBar = ({documents, setDocuments, index, label}) => {
  const [defaultDocuments] = useState(documents)

  const fuse = new Fuse(defaultDocuments, {
    keys: index,
  })

  const handleSearch = (event) => {
    const query = event.target.value
    if (query === '') return setDocuments(defaultDocuments)

    const result = fuse.search(query)

    setDocuments(result.map(({item}) => item))
  }

  return (
    <Container>
      <SearchIcon icon={faSearch} size='lg' />
      <InputBox type='text' name='search' placeholder={label} onChange={handleSearch} />
    </Container>
  )
}

export default SearchBar
