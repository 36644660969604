import React from 'react'
import styled from 'styled-components'
import {useStaticQuery, graphql, Link} from 'gatsby'
import {useAmplitude} from 'hooks'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram, faTwitter, faYoutube, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import {NewTag} from 'components'
import WordLogo from './wordLogo'

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 50px 0;

  background-color: var(--dark-blue);
  color: var(--white);

  a {
    color: var(--white);
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: var(--mobile-width);
  margin-bottom: 25px;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1200px) {
    max-width: var(--content-width);
  }
`

const LogoAndSocials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 25px;
`

const Logo = styled(WordLogo)`
  margin-bottom: 25px;
  height: 100px;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    width: 18rem;
  }
`

const Socials = styled.div`
  display: flex;
  justify-content: space-between;

  width: 85%;
`

const Icon = styled.a`
  font-size: 1.75rem;

  @media only screen and (min-width: 700px) {
    transition: var(--transition);

    &:hover {
      color: var(--blue);
    }
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    align-items: flex-start;

    margin-bottom: 0;

    a {
      transition: var(--transition);
    }

    a:hover {
      color: var(--blue);
    }
  }
`

const Column = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-align: left;

  margin-bottom: 20px;

  width: 85%;

  & > a {
    margin-bottom: 13px;
  }

  h4 {
    margin-top: 0;
  }

  @media only screen and (min-width: 700px) {
    align-items: flex-start;

    margin: 0 25px;
    width: unset;

    text-align: left;
  }
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 600;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;

    width: var(--mobile-width);
  }

  @media only screen and (min-width: 1200px) {
    max-width: var(--content-width);
  }
`

const TermsAndPrivacy = styled.div`
  display: flex;
  justify-content: space-between;

  width: 30ch;
  margin-bottom: 10px;

  @media only screen and (min-width: 700px) {
    margin-bottom: 0;
  }
`

export const Footer = () => {
  const {logEvent} = useAmplitude()
  const {allStrapiProject, allStrapiTopic} = useStaticQuery(graphql`
    query FooterQuery {
      allStrapiTopic(sort: {fields: published_at, order: DESC}, limit: 6) {
        nodes {
          title
          slug
        }
      }
      allStrapiProject(sort: {fields: published_at, order: DESC}, limit: 6) {
        nodes {
          shortTitle
          slug
        }
      }
    }
  `)

  const projects = allStrapiProject.nodes
  const topics = allStrapiTopic.nodes

  const logClick = (buttonName, data = {}) => logEvent('footer-button-click', {buttonName, ...data})

  return (
    <FooterContainer>
      <Content>
        <LogoAndSocials>
          <Logo color='var(--white)' />
          <Socials>
            <Icon
              aria-label='Facebook'
              href='https://www.facebook.com/TheCodexMe/'
              target='_blank'
              rel='noopener noreferrer external'
              onClick={() => logClick('facebook')}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Icon>
            <Icon
              aria-label='Instagram'
              href='https://www.instagram.com/thecodex.me/?hl=en'
              target='_blank'
              rel='noopener noreferrer external'
              onClick={() => logClick('instagram')}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Icon>
            <Icon
              aria-label='Twitter'
              href='https://twitter.com/thecodexme'
              target='_blank'
              rel='noopener noreferrer external'
              onClick={() => logClick('twitter')}
            >
              <FontAwesomeIcon icon={faTwitter} />
            </Icon>
            <Icon
              aria-label='YouTube'
              href='https://www.youtube.com/channel/UCDD0bZ4NS6AayT-VLMmILHg'
              target='_blank'
              rel='noopener noreferrer external'
              onClick={() => logClick('youtube')}
            >
              <FontAwesomeIcon icon={faYoutube} />
            </Icon>
            <Icon
              aria-label='LinkedIn'
              href='https://www.linkedin.com/company/thecodex/'
              target='_blank'
              rel='noopener noreferrer external'
              onClick={() => logClick('linkedin')}
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </Icon>
          </Socials>
        </LogoAndSocials>
        <Links>
          <Column>
            <h4>Company</h4>

            <Link to='/about' onClick={() => logClick('about')}>
              About
            </Link>
            <Link to='/pathways' onClick={() => logClick('pathways')} style={{position: 'relative'}}>
              Pathways
              <NewTag color='var(--yellow)' />
            </Link>
            <Link to='/projects' onClick={() => logClick('projects')}>
              Projects
            </Link>
            <Link to='/topics' onClick={() => logClick('topics')}>
              Topics
            </Link>
            <Link to='/pricing' onClick={() => logClick('pricing')}>
              Pricing
            </Link>
            <a href='https://blog.thecodex.me' onClick={() => logClick('blog')}>
              Blog
            </a>
          </Column>
          <Column>
            <h4>Projects</h4>
            {projects.map((project, i) => (
              <Link
                key={i}
                to={`/projects/${project.slug}`}
                onClick={() => logClick('project-preview', {slug: project.slug})}
              >
                {project.shortTitle}
              </Link>
            ))}
          </Column>
          <Column>
            <h4>Topics</h4>
            {topics.map((topic, i) => (
              <Link key={i} to={`/topics/${topic.slug}`} onClick={() => logClick('topic-preview', {slug: topic.slug})}>
                {topic.title}
              </Link>
            ))}
          </Column>
        </Links>
      </Content>
      <BottomSection>
        <TermsAndPrivacy>
          <a
            href='https://www.notion.so/Terms-of-Service-fe1fcd234a38487098a8bef1cfc86da7'
            target='_blank'
            rel='noopener noreferrer external'
            onClick={() => logClick('terms-of-service')}
          >
            Terms of Service
          </a>
          <a
            href='https://app.termly.io/document/privacy-policy/5cc056ef-4bb7-46a4-b51d-8b34c6bba8b6'
            target='_blank'
            rel='noopener noreferrer external'
            onClick={() => logClick('privacy-policy')}
          >
            Privacy Policy
          </a>
        </TermsAndPrivacy>
        <div>© {new Date().getFullYear()} TheCodex</div>
      </BottomSection>
    </FooterContainer>
  )
}

export default Footer
