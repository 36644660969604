import React from 'react'
import styled from 'styled-components'

const ProgressBarContainer = styled.div`
  width: 100%;
`

const Label = styled.p`
  font-size: 0.9em;
  margin-bottom: 0.25em;
`

const BarContainer = styled.div`
  position: relative;

  width: 100%;
  height: 8px;
  border-radius: 4px;

  background-color: var(--light-grey);
`

const ProgressBar = styled.div`
  position: absolute;

  width: ${({percentage}) => `${percentage * 100}%`};
  height: 100%;
  border-radius: 4px;
  background-color: ${({percentage}) =>
    percentage === 1 ? `var(--yellow)` : `var(--green)`};
`

export default ({percentage}) => (
  <ProgressBarContainer>
    <Label>{percentage * 100 ?? '–'}% Complete</Label>
    <BarContainer>
      <ProgressBar percentage={percentage} />
    </BarContainer>
  </ProgressBarContainer>
)
