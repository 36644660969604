import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram, faTwitter, faYoutube, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 125px;

  background-color: ${({color}) => color};
  color: var(--white);

  a {
    color: var(--white);
  }

  @media only screen and (min-width: 700px) {
    height: 75px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: var(--mobile-width);
  height: 60%;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    height: unset;
    width: var(--mobile-width);
  }
`

const Socials = styled.div`
  display: flex;
  justify-content: space-between;

  width: 250px;
`

const Icon = styled.a`
  font-size: 1.75rem;

  @media only screen and (min-width: 700px) {
    transition: var(--transition);

    &:hover {
      color: var(--blue);
    }
  }
`

const TermsAndPrivacy = styled.div`
  display: flex;
  justify-content: space-between;

  width: 30ch;
  margin-bottom: 10px;

  @media only screen and (min-width: 700px) {
    margin-bottom: 0;
  }
`

const AppFooter = ({theme = 'light'}) => (
  <Footer color={theme === 'light' ? 'var(--dark-blue)' : 'var(--black)'}>
    <Content>
      <Socials>
        <Icon href='https://www.facebook.com/TheCodexMe/' target='_blank' rel='noopener noreferrer external'>
          <FontAwesomeIcon icon={faFacebook} />
        </Icon>
        <Icon href='https://www.instagram.com/thecodex.me/?hl=en' target='_blank' rel='noopener noreferrer external'>
          <FontAwesomeIcon icon={faInstagram} />
        </Icon>
        <Icon href='https://twitter.com/thecodexme' target='_blank' rel='noopener noreferrer external'>
          <FontAwesomeIcon icon={faTwitter} />
        </Icon>
        <Icon
          href='https://www.youtube.com/channel/UCDD0bZ4NS6AayT-VLMmILHg'
          target='_blank'
          rel='noopener noreferrer external'
        >
          <FontAwesomeIcon icon={faYoutube} />
        </Icon>
        <Icon href='https://www.linkedin.com/company/thecodex/' target='_blank' rel='noopener noreferrer external'>
          <FontAwesomeIcon icon={faLinkedinIn} />
        </Icon>
      </Socials>
      <TermsAndPrivacy>
        <a
          href='https://www.notion.so/Terms-of-Service-fe1fcd234a38487098a8bef1cfc86da7'
          target='_blank'
          rel='noopener noreferrer external'
        >
          Terms of Service
        </a>
        <a
          href='https://app.termly.io/document/privacy-policy/5cc056ef-4bb7-46a4-b51d-8b34c6bba8b6'
          target='_blank'
          rel='noopener noreferrer external'
        >
          Privacy Policy
        </a>
      </TermsAndPrivacy>
    </Content>
  </Footer>
)

export default AppFooter
