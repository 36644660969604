import {createGlobalStyle} from 'styled-components'
import 'fontsource-inter'
import 'fontsource-inter/600.css'
import 'fontsource-inter/800.css'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  html {
    font-size: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  body {
    --black: #02152B;
    --white: #fdfdfd;
    --grey: #707780;
    --dark-grey: #707780;
    --medium-grey: #CFD1D3;
    --light-grey: #f1f3f6;
    --blue: #3b92f6;
    --blue-active: #0F65C9;
    --dark-blue: #012c5e;
    --light-blue: #f5f9fd;
    --green: #42c296;
    --green-active: #0DA46F;
    --yellow: #f5c342;
    --red: #ef6666;
    --violet: #d2dfff;
    --content-width: 1200px;
    --reading-width: 80ch;
    --mobile-width: 90vw;
    --secondary-font: 'Inter', sans-serif;
    --primary-font: 'Inter', sans-serif;
    --shadow: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));
    --transition: 100ms ease-in;
    
    background-color: var(--light-blue);
    color: var(--black);
    font-family: var(--secondary-font);

    margin: 0;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
    font-family: var(--primary-font);
  }

  h4, h5, h6 {
    font-weight: 600;
  }

  h1 {
    font-size: 2.625rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5, h6 { 
    font-size: 1rem;
  }
`
