import React from 'react'

export default ({color = 'var(--blue)', checked = false, ...props}) => {
  if (checked) {
    return (
      <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          d='M0 17.5C0 7.83502 7.83502 0 17.5 0V0C27.165 0 35 7.83502 35 17.5V17.5C35 27.165 27.165 35 17.5 35V35C7.83502 35 0 27.165 0 17.5V17.5Z'
          fill='#FDFDFD'
        />
        <path
          d='M17.5 0C14.0388 0 10.6554 1.02636 7.77753 2.94928C4.89967 4.87221 2.65665 7.60533 1.33212 10.803C0.00758248 14.0007 -0.338976 17.5194 0.336265 20.9141C1.01151 24.3087 2.67822 27.4269 5.12564 29.8744C7.57306 32.3218 10.6913 33.9885 14.0859 34.6637C17.4806 35.339 20.9993 34.9924 24.197 33.6679C27.3947 32.3433 30.1278 30.1003 32.0507 27.2225C33.9736 24.3446 35 20.9612 35 17.5C34.9946 12.8604 33.1492 8.41228 29.8684 5.13155C26.5877 1.85083 22.1396 0.00536242 17.5 0V0ZM25.8346 14.4357L15.9619 23.8587C15.7109 24.0976 15.3777 24.2308 15.0312 24.2308C14.6847 24.2308 14.3515 24.0976 14.1004 23.8587L9.16542 19.1472C8.90759 18.9002 8.75834 18.561 8.75044 18.2041C8.74253 17.8471 8.87663 17.5017 9.12327 17.2435C9.36992 16.9854 9.70894 16.8357 10.0659 16.8273C10.4228 16.819 10.7684 16.9526 11.0269 17.199L15.0312 21.0231L23.9731 12.4874C24.2316 12.2411 24.5772 12.1074 24.9341 12.1158C25.2911 12.1242 25.6301 12.2738 25.8767 12.532C26.1234 12.7901 26.2575 13.1356 26.2496 13.4925C26.2417 13.8495 26.0924 14.1887 25.8346 14.4357V14.4357Z'
          fill={color}
        />
      </svg>
    )
  } else {
    return (
      <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <circle cx='17.5' cy='17.5' r='16' stroke={color} strokeWidth='3' />
      </svg>
    )
  }
}
