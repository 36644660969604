import React, {useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faCaretDown, faTimes, faUserCircle} from '@fortawesome/free-solid-svg-icons'

import {LinkButton, WordLogo, NewTag} from 'components'
import {useAmplitude, useAuth, useUser} from 'hooks'

const HeaderContainer = styled.header<{colors: ColorsObject}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;
  background-color: ${({colors}) => colors.bkgColor};
  color: ${({colors}) => colors.textColor};

  @media only screen and (min-width: 900px) {
    overflow: visible;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--mobile-width);
  height: 25px;
`

const Navbar = styled.nav`
  display: none;

  @media only screen and (min-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const IconButton = styled.button<{theme: 'light' | 'dark'}>`
  border: none;
  padding: 0;
  background: none;

  color: ${({theme}) => (theme === 'light' ? 'var(--black)' : 'var(--white)')};

  &:active {
    color: ${({theme}) => (theme === 'light' ? 'var(--blue)' : 'var(--yellow)')};
  }

  @media only screen and (min-width: 900px) {
    display: none;
  }
`

const NavMenu = styled.div<{open: boolean}>`
  visibility: ${({open}) => (open ? `visible` : `collapse`)};
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;

  width: 100vw;
  height: 100vh;
  padding: 25px 5vw;

  background-color: var(--dark-blue);

  @media only screen and (min-width: 900px) {
    display: none;
  }
`

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--mobile-width);
  height: 25px;
`

const MenuNavBar = styled.nav`
  margin-top: 100px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const styledMenuItem = css`
  display: block;

  margin: 15px 25px 0;
  text-align: right;
  font-size: 3em;
  font-weight: bold;

  color: var(--white);
`

const StyledMenuLink = styled(Link)`
  ${styledMenuItem}
`

const StyledMenuButton = styled.button`
  ${styledMenuItem}

  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
`

const StyledMenuAnchor = styled.a`
  ${styledMenuItem}
`

const StyledLink = styled(Link)<{color?: string}>`
  position: relative;
  color: ${({color}) => (color ? color : 'inherit')};
  margin-left: 35px;
  transition: var(--transition);
  font-family: var(--secondary-font);
  font-weight: 600;

  &:hover {
    color: var(--blue);
  }
`

const StyledLinkButton = styled(LinkButton)`
  padding: 10px 20px;
  margin-left: 25px;

  font-family: var(--secondary-font);
  font-weight: 600;
`

const DiscordButton = styled.a`
  color: #738adb !important;
  font-size: 2em;
  margin-left: 35px;
  margin-top: 0.2em;
`

const ProfileButton = styled.div`
  flex-shrink: 0;
  position: relative;
  padding: 0;
  border: none;
  color: var(--blue);
  background: none;

  font-size: 2em;
  margin-left: 25px;

  &:hover {
    cursor: pointer;
  }
`

const DropArrow = styled(FontAwesomeIcon)<{open: boolean}>`
  transition: var(--transition);

  opacity: ${({open}) => (open ? 0.5 : 1)};

  ${ProfileButton}:hover & {
    opacity: 0.5;
  }
`

const DropdownMenu = styled.div<{visible: boolean}>`
  display: none;
  @media only screen and (min-width: 900px) {
    pointer-events: none;
    display: unset;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 110%;
    right: 0;

    width: 150px;
    border-radius: 10px;
    padding: 10px 0;

    background-color: var(--white);
    filter: var(--shadow);
    visibility: ${({visible}) => (visible ? 'visible' : 'collapse')};
  }
`

const dropdownButtonStyle = css`
  display: block;

  pointer-events: auto;
  width: 100%;
  padding: 5px 0 5px 16px;

  text-align: left;
  font-family: var(--secondary-font);
  font-size: 1rem;
  font-weight: 600;

  transition: background-color var(--transition);

  &:hover {
    cursor: pointer;
    background-color: var(--light-grey);
  }
`

const DropdownActionButton = styled.button`
  ${dropdownButtonStyle}

  border: none;
  background: none;
`

const DropdownLinkButton = styled(Link)`
  ${dropdownButtonStyle}
`

type StyleType = 'transparent' | 'light' | 'dark'
interface HeaderProps {
  theme: StyleType
}

interface ColorsObject {
  bkgColor: string
  textColor: string
}

const useColors = (style: StyleType) => {
  const [colors, setColors] = useState<ColorsObject>({
    bkgColor: 'var(--light-blue)',
    textColor: 'var(--black)',
  })

  useEffect(() => {
    switch (style) {
      case 'transparent':
        setColors({bkgColor: 'transparent', textColor: 'var(--black)'})
        break
      case 'light':
        setColors({bkgColor: 'var(--white)', textColor: 'var(--black)'})
        break
      case 'dark':
        setColors({bkgColor: 'var(--black)', textColor: 'var(--white)'})
        break
    }
  }, [style])

  return colors
}

export const Header = ({theme = 'light'}: HeaderProps) => {
  const {logEvent} = useAmplitude()
  const colors = useColors(theme)
  const [isMenuOpen, setMenuOpen] = useState(false)

  const logClick = (buttonName: string) => logEvent('header-button-click', {buttonName})

  return (
    <HeaderContainer colors={colors}>
      <Content>
        <Link aria-label='Home Page' to='/' onClick={() => logClick('home')}>
          <WordLogo />
        </Link>
        <Navbar>
          <StyledLink activeStyle={{color: 'var(--blue)'}} to='/pricing' onClick={() => logClick('pricing')}>
            Pricing
          </StyledLink>
          <StyledLink
            partiallyActive
            activeStyle={{color: 'var(--blue)'}}
            to='/pathways'
            onClick={() => logClick('pathways')}
          >
            Pathways
            <NewTag />
          </StyledLink>
          <StyledLink
            partiallyActive
            activeStyle={{color: 'var(--blue)'}}
            to='/projects'
            onClick={() => logClick('projects')}
          >
            Projects
          </StyledLink>
          <StyledLink
            partiallyActive
            activeStyle={{color: 'var(--blue)'}}
            to='/topics'
            onClick={() => logClick('topics')}
          >
            Topics
          </StyledLink>
          <StyledLink activeStyle={{color: 'var(--blue)'}} to='/app/login' onClick={() => logClick('login')}>
            Login
          </StyledLink>
          <StyledLinkButton to='/app/get-started' tier='primary' onClick={() => logClick('get-started')}>
            Get Started
          </StyledLinkButton>
        </Navbar>
        <IconButton
          aria-label='Open Navigation'
          theme={theme === 'dark' ? 'dark' : 'light'}
          onClick={() => setMenuOpen(true)}
        >
          <FontAwesomeIcon icon={faBars} size='3x' />
        </IconButton>
        <NavMenu open={isMenuOpen}>
          <MenuHeader>
            <Link aria-label='Home Page' to='/' onClick={() => logClick('home')}>
              <WordLogo color='var(--white)' />
            </Link>
            <IconButton aria-label='Close Navigation' theme='dark' onClick={() => setMenuOpen(false)}>
              <FontAwesomeIcon icon={faTimes} size='3x' />
            </IconButton>
          </MenuHeader>
          <MenuNavBar>
            <StyledMenuLink activeStyle={{color: 'var(--yellow)'}} to='/' onClick={() => logClick('home')}>
              Home
            </StyledMenuLink>
            <StyledMenuLink activeStyle={{color: 'var(--yellow)'}} to='/pricing' onClick={() => logClick('pricing')}>
              Pricing
            </StyledMenuLink>
            <StyledMenuLink
              partiallyActive
              activeStyle={{color: 'var(--yellow)'}}
              to='/pathways'
              onClick={() => logClick('pathways')}
            >
              Pathways
            </StyledMenuLink>
            <StyledMenuLink
              partiallyActive
              activeStyle={{color: 'var(--yellow)'}}
              to='/projects'
              onClick={() => logClick('projects')}
            >
              Projects
            </StyledMenuLink>
            <StyledMenuLink
              partiallyActive
              activeStyle={{color: 'var(--yellow)'}}
              to='/topics'
              onClick={() => logClick('topics')}
            >
              Topics
            </StyledMenuLink>
            <div style={{height: 50}} />
            <StyledMenuLink activeStyle={{color: 'var(--yellow)'}} to='/app/login' onClick={() => logClick('login')}>
              Login
            </StyledMenuLink>
            <StyledMenuLink
              activeStyle={{color: 'var(--yellow)'}}
              to='/app/get-started'
              onClick={() => logClick('get-started')}
            >
              Get Started
            </StyledMenuLink>
          </MenuNavBar>
        </NavMenu>
      </Content>
    </HeaderContainer>
  )
}

export const AppHeader = ({theme = 'light'}: HeaderProps) => {
  const {signOut} = useAuth()
  const {logEvent} = useAmplitude()
  const {user} = useUser()
  const colors = useColors(theme)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const dropdownMenuRef = useRef<HTMLDivElement>(null)

  const logClick = (buttonName: string) => logEvent('header-button-click', {buttonName})

  useEffect(() => {
    if (isDropdownOpen) {
      const handleClickOutside = (event: any) => {
        if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
          setDropdownOpen(false)
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [dropdownMenuRef, isDropdownOpen])

  return (
    <HeaderContainer colors={colors}>
      <Content>
        <Link aria-label='Dashboard' to='/app/dashboard' onClick={() => logClick('dashboard')}>
          <WordLogo color={colors.textColor} />
        </Link>
        <Navbar>
          <StyledLink
            color={colors.textColor}
            activeStyle={{color: 'var(--blue)'}}
            to='/app/dashboard'
            onClick={() => logClick('dashboard')}
          >
            Dashboard
          </StyledLink>
          <StyledLink
            color={colors.textColor}
            activeStyle={{color: 'var(--blue)'}}
            to='/pathways'
            onClick={() => logClick('pathways')}
          >
            Pathways
            <NewTag />
          </StyledLink>
          <StyledLink
            color={colors.textColor}
            activeStyle={{color: 'var(--blue)'}}
            to='/projects'
            onClick={() => logClick('projects')}
          >
            Projects
          </StyledLink>
          <StyledLink
            color={colors.textColor}
            activeStyle={{color: 'var(--blue)'}}
            to='/topics'
            onClick={() => logClick('topics')}
          >
            Topics
          </StyledLink>
          <ProfileButton aria-label='More Options' onClick={() => setDropdownOpen(!isDropdownOpen)}>
            <FontAwesomeIcon icon={faUserCircle} style={{marginRight: 5}} />
            <DropArrow open={isDropdownOpen} icon={faCaretDown} style={{marginBottom: 3}} size='xs' />
            <DropdownMenu ref={dropdownMenuRef} visible={isDropdownOpen}>
              <DropdownLinkButton to='/app/settings' onClick={() => logClick('settings')}>
                Settings
              </DropdownLinkButton>
              <DropdownActionButton
                onClick={() => {
                  logClick('sign-out')
                  signOut()
                }}
              >
                Sign out
              </DropdownActionButton>
            </DropdownMenu>
          </ProfileButton>
          {user?.proStatus === 'inactive' && (
            <StyledLinkButton
              to='/pricing'
              tier='primary'
              buttoncolor='var(--green)'
              activecolor='var(--green-active)'
              onClick={() => logClick('upgrade')}
            >
              Become a Creator
            </StyledLinkButton>
          )}
        </Navbar>
        <IconButton
          aria-label='Open Navigation'
          theme={theme === 'dark' ? 'dark' : 'light'}
          onClick={() => setMenuOpen(true)}
        >
          <FontAwesomeIcon icon={faBars} size='3x' />
        </IconButton>
        <NavMenu open={isMenuOpen}>
          <MenuHeader>
            <Link aria-label='Dashboard' to='/app/dashboard' onClick={() => logClick('dashboard')}>
              <WordLogo color='var(--white)' />
            </Link>
            <IconButton aria-label='Close Navigation' theme='dark' onClick={() => setMenuOpen(false)}>
              <FontAwesomeIcon icon={faTimes} size='3x' />
            </IconButton>
          </MenuHeader>
          <MenuNavBar>
            <StyledMenuLink
              activeStyle={{color: 'var(--yellow)'}}
              to='/app/dashboard'
              onClick={() => logClick('dashboard')}
            >
              Dashboard
            </StyledMenuLink>
            <StyledMenuLink
              partiallyActive
              activeStyle={{color: 'var(--yellow)'}}
              to='/pathways'
              onClick={() => logClick('pathways')}
            >
              Pathways
            </StyledMenuLink>
            <StyledMenuLink
              partiallyActive
              activeStyle={{color: 'var(--yellow)'}}
              to='/projects'
              onClick={() => logClick('projects')}
            >
              Projects
            </StyledMenuLink>
            <StyledMenuLink
              partiallyActive
              activeStyle={{color: 'var(--yellow)'}}
              to='/topics'
              onClick={() => logClick('topics')}
            >
              Topics
            </StyledMenuLink>
            <div style={{height: 50}} />
            <StyledMenuLink
              activeStyle={{color: 'var(--yellow)'}}
              to='/app/settings'
              onClick={() => logClick('settings')}
            >
              Settings
            </StyledMenuLink>
            <StyledMenuButton
              onClick={() => {
                logClick('sign-out')
                signOut()
                setMenuOpen(false)
              }}
            >
              Sign out
            </StyledMenuButton>
          </MenuNavBar>
        </NavMenu>
      </Content>
    </HeaderContainer>
  )
}
