import React from 'react'
import styled from 'styled-components'

const TagStyle = styled.p`
  position: absolute;

  margin: 0;

  right: -0.6em;
  top: -0.6em;

  color: ${({color}) => color};
  font-size: 0.6em;
  font-weight: 800;
`

const NewTag = ({color = 'var(--blue)'}) => <TagStyle color={color}>NEW</TagStyle>

export default NewTag
