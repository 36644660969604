import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'

const labelStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  margin-bottom: 25px;

  font-weight: bold;
  font-size: 1em;
`

const InputStyle = styled.label`
  ${labelStyle}

  input {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border: none;
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px 15px;
    width: 100%;

    background-color: var(--light-grey);
    color: var(--black);

    font-family: var(--secondary-font);
    font-size: 1em;
  }
`

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string
}

export const Input = ({label, ...props}: InputProps) => (
  <InputStyle>
    {label}
    <input {...props} />
  </InputStyle>
)

const InputBox = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`

const VisibilityToggle = styled(FontAwesomeIcon)`
  position: absolute;
  align-self: center;

  right: 5%;
  font-size: 1em;
  z-index: 1;

  @media only screen and (min-width: 700px) {
    &:hover {
      cursor: pointer;
    }
  }
`

export const Password = ({label, placeholder, ...props}: InputProps) => {
  const [passwordIsVisible, setVisibility] = useState(false)

  return (
    <InputStyle>
      {label}
      <InputBox>
        <input
          {...props}
          type={passwordIsVisible ? 'text' : 'password'}
          placeholder={passwordIsVisible ? placeholder : placeholder?.split('').reduce((dots) => dots.concat('•'), '')}
        />
        <VisibilityToggle
          icon={passwordIsVisible ? faEyeSlash : faEye}
          onClick={() => setVisibility(!passwordIsVisible)}
        />
      </InputBox>
    </InputStyle>
  )
}

const RowStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

interface RowProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  gapSize: string
  children: React.ReactElement<any>[]
}

export const Row = ({gapSize, children}: RowProps) => {
  const newChildren: React.ReactElement<any>[] = []

  React.Children.forEach(children, (child, index) => {
    newChildren.push(child)

    if (index !== children.length - 1) {
      newChildren.push(<div key={`${index}.5`} style={{width: gapSize}} />)
    }
  })

  return <RowStyle>{newChildren}</RowStyle>
}

const SelectStyle = styled.label`
  ${labelStyle}

  select {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border: 2px solid var(--medium-grey);
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px 15px;
    width: 100%;

    background-color: var(--white);
    color: var(--black);

    font-family: var(--secondary-font);
    font-size: 1em;
  }
`

interface DropdownProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label: string
}

export const Dropdown = ({label, children, ...props}: DropdownProps) => {
  return (
    <SelectStyle>
      {label}
      <select {...props}>{children}</select>
    </SelectStyle>
  )
}
