import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {Quotes} from 'images/svgs'

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: #d9efff;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    max-width: var(--content-width);
  }
`

const SpeechContainer = styled.div`
  z-index: 1;

  @media only screen and (min-width: 700px) {
    max-width: 50%;
  }

  @media only screen and (min-width: 1200px) {
    h3 {
      margin: 45px 0;
    }
  }
`

const Avi = styled(Img)`
  width: 100%;

  @media only screen and (min-width: 700px) {
    align-self: flex-end;
    margin-right: 5vw;
  }
`

const Quote = styled.p`
  position: relative;

  @media only screen and (min-width: 1200px) {
    padding-left: 25px;
  }
`

const FloatingQuotes = styled(Quotes)`
  position: absolute;
  z-index: -1;

  width: 100px;
  height: 100px;

  top: 0;
  left: -2.5vw;
  transform: translateY(-50%);

  @media only screen and (min-width: 700px) {
    left: -25px;
  }

  @media only screen and (min-width: 1200px) {
    width: 200px;
    height: 200px;
  }
`

const AviQuote = () => {
  const {bigAvi} = useStaticQuery(graphql`
    query {
      bigAvi: file(relativePath: {eq: "avi-big.webp"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Content>
        <Avi alt='Avi, your new instructor.' fluid={bigAvi.childImageSharp.fluid} />
        <SpeechContainer>
          <h3>Hey! I’m Avi, and I’ll teach you how to build!</h3>
          <Quote>
            <FloatingQuotes />I{`'`}m Avi, your new Python instructor and founder of The Codex.
            <br />
            <br />
            Three years ago I was stuck. I wasn’t learning anything in my theory-filled CS classes, my friends took
            pictures of me sleeping in lecture, and I was questioning why I was even learning CS to begin with. I had
            ideas but I was clueless on execution.
            <br />
            <br />
            After watching hours of webcasted YouTube videos, enrolling in course after course, and struggling to stay
            motivated, I finally obtained the skills to bring my ideas to life. I realized how lonely and gruesome this
            process was.
            <br />
            <br />
            If you’re a student just like me, you know exactly what it’s like to be stuck in course hell. The best
            programmers of today learned by doing, not by reading syntax in a textbook.
            <br />
            <br />
            Join The Codex. Become a creator.
          </Quote>
        </SpeechContainer>
      </Content>
    </Section>
  )
}

export default AviQuote
