import React, {PropsWithChildren} from 'react'
import {Link, GatsbyLinkProps} from 'gatsby'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'

interface ButtonProps {
  tier: 'primary' | 'secondary' | 'tertiary'
  buttoncolor?: string
  activecolor?: string
}

const boxStyle = css<{disabled?: boolean}>`
  display: inline-block;

  border: 2px solid;
  border-radius: 5px;
  padding: 15px 40px;

  text-align: center;
  font-size: 1em;
  font-weight: 600;
  white-space: nowrap;

  @media only screen and (min-width: 700px) {
    transition: var(--transition);
    pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};

    &:hover {
      cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};
    }
  }
`

const colorPicker = (disabled, color = 'var(--blue)', disabledColor = 'var(--dark-grey)') =>
  disabled ? disabledColor : color

interface ButtonStyleProps {
  disabled?: boolean
  buttoncolor?: string
  activecolor?: string
}

const primaryStyle = css<ButtonStyleProps>`
  ${boxStyle}
  background-color: ${(props) => colorPicker(props.disabled, props.buttoncolor)};
  border-color: ${(props) => colorPicker(props.disabled, props.buttoncolor)};
  color: var(--white);
  font-family: var(--primary-font);

  @media only screen and (min-width: 700px) {
    ${({disabled, activecolor}) =>
      disabled
        ? {}
        : `
        &:hover {
          background-color: ${activecolor ?? 'var(--blue-active)'};
          border-color: ${activecolor ?? 'var(--blue-active)'};
        }
    `}
  }
`

const secondaryStyle = css<ButtonStyleProps>`
  ${boxStyle}
  background-color: var(--white);
  border-color: ${(props) => colorPicker(props.disabled, props.buttoncolor)};
  color: ${(props) => colorPicker(props.disabled, props.buttoncolor)};
  font-family: var(--primary-font);

  @media only screen and (min-width: 700px) {
    ${({disabled}) =>
      disabled
        ? {}
        : `
        &:hover {
          background-color: var(--light-blue)};
        }
    `}
  }
`

const tertiaryStyle = css<ButtonStyleProps>`
  border: none;
  padding: 0;

  font-size: 1em;
  font-family: var(--secondary-font);

  background-color: transparent;
  color: ${(props) => colorPicker(props.disabled, props.buttoncolor)};

  @media only screen and (min-width: 700px) {
    ${({disabled}) =>
      disabled
        ? `pointer-events: none;`
        : `
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
    `}
  }
`

const PrimaryActionButton = styled.button<ButtonStyleProps>`
  ${primaryStyle}
`

const PrimaryAnchorButton = styled.a<ButtonStyleProps>`
  ${primaryStyle}
`

const PrimaryLinkButton = styled(Link)<ButtonStyleProps>`
  ${primaryStyle}
`

const SecondaryActionButton = styled.button<ButtonStyleProps>`
  ${secondaryStyle}
`

const SecondaryAnchorButton = styled.a<ButtonStyleProps>`
  ${secondaryStyle}
`

const SecondaryLinkButton = styled(Link)<ButtonStyleProps>`
  ${secondaryStyle}
`

const TertiaryActionButton = styled.button<ButtonStyleProps>`
  ${tertiaryStyle}
`

const TertiaryAnchorButton = styled.a<ButtonStyleProps>`
  ${tertiaryStyle}
`

const TertiaryLinkButton = styled(Link)<ButtonStyleProps>`
  ${tertiaryStyle}
`

const IconStyle = styled(FontAwesomeIcon)`
  margin-left: 0.5ch;
`

interface ActionButtonProps
  extends ButtonProps,
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const ActionButton = ({ref, children, tier, ...props}: ActionButtonProps) => {
  switch (tier) {
    case 'primary':
      return <PrimaryActionButton {...props}>{children}</PrimaryActionButton>
    case 'secondary':
      return <SecondaryActionButton {...props}>{children}</SecondaryActionButton>
    case 'tertiary':
      return <TertiaryActionButton {...props}>{children}</TertiaryActionButton>
  }
}

interface AnchorButtonProps
  extends ButtonProps,
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  href: string
}

export const AnchorButton = ({ref, children, tier, ...props}: AnchorButtonProps) => {
  switch (tier) {
    case 'primary':
      return (
        <PrimaryAnchorButton {...props}>
          {children}
          {props.target === '_blank' && <IconStyle icon={faExternalLinkAlt} size='1x' />}
        </PrimaryAnchorButton>
      )
    case 'secondary':
      return (
        <SecondaryAnchorButton {...props}>
          {children}
          {props.target === '_blank' && <IconStyle icon={faExternalLinkAlt} size='1x' />}
        </SecondaryAnchorButton>
      )
    case 'tertiary':
      return (
        <TertiaryAnchorButton {...props}>
          {children}
          {props.target === '_blank' && <IconStyle icon={faExternalLinkAlt} size='1x' />}
        </TertiaryAnchorButton>
      )
  }
}

interface LinkButtonProps<TState> extends ButtonProps, GatsbyLinkProps<TState> {}

export function LinkButton<TState>({ref, children, tier, to, ...props}: PropsWithChildren<LinkButtonProps<TState>>) {
  switch (tier) {
    case 'primary':
      return (
        <PrimaryLinkButton to={to} {...props}>
          {children}
        </PrimaryLinkButton>
      )
    case 'secondary':
      return (
        <SecondaryLinkButton to={to} {...props}>
          {children}
        </SecondaryLinkButton>
      )
    case 'tertiary':
      return (
        <TertiaryLinkButton to={to} {...props}>
          {children}
        </TertiaryLinkButton>
      )
  }
}
