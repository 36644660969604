import React from 'react'
import styled from 'styled-components'

import {AppHeader, Header} from 'components'
import {useAuth} from 'hooks'
import GlobalStyle from './globalStyle'
import BackgroundSVG from 'images/background-large.svg'

const Background = styled.div`
  background: center / cover url(${BackgroundSVG});
  background-color: var(--light-blue);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: ${({noGap}) => (noGap ? `100vh ` : `calc(100vh - 75px)`)}; // 75px comes from Header height
`

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ModalLayout = ({children, noHeader}) => {
  const {user} = useAuth()

  if (user) {
    return (
      <Background>
        <GlobalStyle />
        {!noHeader && <AppHeader theme='transparent' />}
        <Content noGap={noHeader}>
          <Main>{children}</Main>
        </Content>
      </Background>
    )
  } else {
    return (
      <Background>
        <GlobalStyle />
        {!noHeader && <Header theme='transparent' />}
        <Content noGap={noHeader}>
          <Main>{children}</Main>
        </Content>
      </Background>
    )
  }
}

export default ModalLayout
