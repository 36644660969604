export {default as LockSVG} from './lock.inline.svg'
export {default as PlaySVG} from './play.inline.svg'
export {default as BlockOne} from './block-1.inline.svg'
export {default as BlockTwo} from './block-2.inline.svg'
export {default as BlockThree} from './block-3.inline.svg'
export {default as ValueOne} from './value-1.inline.svg'
export {default as ValueTwo} from './value-2.inline.svg'
export {default as ValueThree} from './value-3.inline.svg'
export {default as Tilde} from './tilde.inline.svg'
export {default as QuestionMark} from './question-mark.inline.svg'
export {default as Check} from './check.inline.svg'
export {default as PersonCareer} from './person-career.inline.svg'
export {default as PersonPassion} from './person-passion.inline.svg'
export {default as PersonStudent} from './person-student.inline.svg'
export {default as TriangleBlockHalf} from './triangle-block-half.inline.svg'
export {default as TriangleBlockFull} from './triangle-block-full.inline.svg'
export {default as Quotes} from './quotes.inline.svg'
export {default as Triangles} from './triangles.inline.svg'
export {default as LetsDoThis} from './lets-do-this.inline.svg'
export {default as StartForFree} from './start-for-free.inline.svg'
export {default as TwoMonthsFree} from './two-months-free.inline.svg'
export {default as AsteroidMobile} from './asteroid-mobile.inline.svg'
export {default as AsteroidDesktop} from './asteroid-desktop.inline.svg'
export {default as Stars1} from './stars-1.inline.svg'
export {default as Backpack} from './backpack.inline.svg'
export {default as BarChart} from './bar-chart.inline.svg'
export {default as CreatorLock} from './creator-lock.inline.svg'
